import React from "react"
import Seo from "src/utils/seo"
import Layout from "components/Layout/Layout"
import SettingsManager from "components/SettingsManager/SettingsManager"
import ProductAd from "components/ProductAd/ProductAd"

export default function Page() {
    return(
        <React.Fragment>
            <Seo title="Product Ads" />
            <Layout showActions={true}>
                <ProductAd page="productad"/>
            </Layout>
            <SettingsManager/>
        </React.Fragment>
    )
}